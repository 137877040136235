import { useEffect, useState } from "react"
import { connect } from "react-redux"

import CardProAdvertising from "../../components/cardProAdvertising/CardProAdvertising"

import "./LimitPatient.scss"
const LimitPatient = (props) => {

    const {
        features,
        clinic,
        history
    } = props

    useEffect(() => {
        if (features?.pro) {
            history.push("/inicio")
        }
    }, [ features ])

    return (
        <div id="limit-patient">
            <div className="page-content">
                <div className="row g-3">
                    <div className="col-12">
                        <div className="page-title">
                            Prontuário Básico
                        </div>
                        {!clinic.isOwner &&
                        <div className="contact-to-owner-advice mt-3">
                            Entre em contato com o <b>Dono da Clínica</b> para contratar os serviços do Prontuário PRO.
                        </div>}
                    </div>
                    <div className={`col-12 ${clinic.isOwner ? "col-md-8" : ""}`}>
                        <div className="vsbox">
                            <div className="vsbox-body">
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="limit-title mb-3">
                                            Atualizações no Vetsmart
                                        </div>
                                        <p>
                                            Nos últimos 12 anos, seguimos firmes no nosso compromisso de apoiar médicos veterinários em todo o Brasil, oferecendo conteúdos gratuitos e ferramentas para facilitar o dia a dia da profissão. Juntos, trilhamos uma jornada incrível, cheia de aprendizados e conquistas.
                                        </p>
                                        <p>
                                            A cada passo, buscamos melhorar e amadurecer nossa plataforma, trazendo recursos inovadores e garantindo que você tenha o melhor suporte no cuidado com os animais.
                                        </p>
                                        <p>
                                            Em 2023, demos um grande salto com o lançamento do <b>Prontuário PRO</b>, que trouxe funcionalidades exclusivas para quem deseja ir ainda mais longe na gestão e atendimento clínico. Esse foi só o começo de uma série de melhorias que estamos implementando para continuar transformando a sua experiência no Vetsmart.
                                        </p>
                                        <p>
                                            Agora, para dar continuidade a essa evolução e garantir que possamos manter um serviço sustentável e de alta qualidade, <b>faremos uma nova mudança a partir de 17 de fevereiro de 2025</b>.
                                        </p>
                                        <p>
                                            <b>O que muda no Prontuário:</b><br />
                                            A partir dessa data, haverá um <b>limite de 150 cadastros de animais no Plano Básico e Prontuário Básico + Assistente Vetsmart</b>. Isso significa que você poderá cadastrar até 150 animais na plataforma para gerenciar seus atendimentos.
                                        </p>
                                        <p>
                                            Se você já possui mais de 150 cadastros hoje, fique tranquilo! Seus dados permanecerão intactos e você continuará acessando todas as informações normalmente. Apenas não será possível adicionar novos cadastros enquanto estiver acima do limite.
                                        </p>
                                        <p>
                                            <b>Por que estamos fazendo essa mudança?</b><br />
                                            Nosso objetivo é continuar investindo na criação de ferramentas e conteúdos que atendam às suas necessidades e elevem a experiência do uso da plataforma. Limitações como essa nos ajudam a sustentar a evolução do Vetsmart e a garantir que possamos oferecer cada vez mais qualidade e inovação para todos.
                                        </p>
                                        <p>
                                            Agradecemos pela sua confiança e por fazer parte da nossa história.
                                        </p>
                                        <p>
                                            <b>Equipe Vetsmart</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { clinic.isOwner && (
                        <div className="col-12 col-md-4">
                            <CardProAdvertising clinic={clinic} features={features} history={history} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(LimitPatient)