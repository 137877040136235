import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary, MenuItem, TextField } from '@mui/material';

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconAI } from "../../assets/icon-ia.svg"

import { addEvent, dayjs, fixDecimalJavascript, formattedCurrency, getProductsAmount, getProductsToSubscription, mandatoryField, saveOnDataLayer, saveOnTracker } from '../../utils';
import { getProntuarioPro } from '../../store/actions';
import { getInstallmentsOfProductYearly } from './Payment';
import { PLAN_MONTHLY, PLAN_YEARLY, PRONTUARIO_AI, PRONTUARIO_AI_MONTHLY, PRONTUARIO_AI_NAME, PRONTUARIO_AI_YEARLY_12X, PRONTUARIO_AI_YEARLY_FROM, PRONTUARIO_AI_YEARLY_TO, PRONTUARIO_LEGACY_PRO_MONTHLY, PRONTUARIO_LEGACY_PRO_YEARLY_TO, PRONTUARIO_PRO, PRONTUARIO_PRO_MONTHLY, PRONTUARIO_PRO_MONTHLY_UPSELL, PRONTUARIO_PRO_NAME, PRONTUARIO_PRO_YEARLY_12X, PRONTUARIO_PRO_YEARLY_FROM, PRONTUARIO_PRO_YEARLY_TO, PRONTUARIO_PRO_YEARLY_UPSELL_12X } from '../../constants';

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import PaymentCardField from '../../components/paymentCardField/PaymentCardField';
import SubscriptionAlertView from './SubscriptionAlertView';

import './ProPaymentForm.scss'
const ProPaymentForm = (props) => {

    const {
        features,
        clinic,
        history
    } = props

    const subscription = features?.subscription

    const products = [
        {
            value: [
                PRONTUARIO_AI
            ],
            title: (
                <>
                    Prontuário Básico<br/>
                    + Assistente Vetsmart <IconAI width={24} height={24} fill="var(--vsprontuario-warning-color)" />
                </>
            ),
            key: "assistant",
            details: [
                "Até 1 usuário",
                "Todas as funcionalidades básicas de atendimento",
                "Lembretes de vacinas com limitação",
                "Compartilhamento de documentos digitais com limitação de uso",
                "Resumo do histórico clínico e diagnóstico diferencial"
            ],
            pricing: {
                yearly: {
                    from: PRONTUARIO_AI_YEARLY_FROM,
                    installments: PRONTUARIO_AI_YEARLY_12X,
                    amount: PRONTUARIO_AI_YEARLY_TO
                },
                monthly: {
                    amount: PRONTUARIO_AI_MONTHLY
                }
            }
        },
        {
            value: [
                PRONTUARIO_AI,
                PRONTUARIO_PRO
            ],
            title: (
                <>Prontuário PRO</>
            ),
            key: "pro",
            details: [
                "Acessos ilimitados",
                "Todas as funcionalidades básicas de atendimento",
                "Recursos avançados de gestão para otimizar a rotina do seu estabelecimento",
                "Lembretes de vacinas ilimitados",
                "Compartilhamento de documentos digitais ilimitados",
                "Resumo do histórico clínico e diagnóstico diferencial"
            ],
            pricing: {
                yearly: {
                    from: PRONTUARIO_PRO_YEARLY_FROM,
                    installments: PRONTUARIO_PRO_YEARLY_12X,
                    amount: PRONTUARIO_PRO_YEARLY_TO
                },
                monthly: {
                    amount: PRONTUARIO_PRO_MONTHLY
                }
            }
        }
    ]

    const PaymentCardFieldRef = (node) => {
        const wasChanged = JSON.stringify(paymentCardField) != JSON.stringify(node)
        if (node && wasChanged) {
            setPaymentCardField(node)
        }
    }

    const [ paymentCardField, setPaymentCardField ] = useState(null)

    const [ currentPlan, setCurrentPlan ] = useState(PLAN_YEARLY)
    const [ currentProduct, setCurrentProduct ] = useState([PRONTUARIO_AI, PRONTUARIO_PRO])
    const [ currentInstallments, setCurrentInstallments ] = useState("1")

    const [ planTab, setPlanTab ] = useState(currentPlan)
    const [ plan, setPlan ] = useState(currentPlan)
    const [ errorPlan, setErrorPlan ] = useState(false)
    const [ helperPlan, setHelperPlan ] = useState("")
    const [ product, setProduct ] = useState(currentProduct)
    const [ accordions, setAccordions ] = useState({})

    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const [ backupInstallments, setBackupInstallments ] = useState(null)
    const [ installments, setInstallments ] = useState("1")
    const [ errorInstallments, setErrorInstallments ] = useState(false)
    const [ helperInstallments, setHelperInstallments ] = useState(mandatoryField)

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = (forceValidation = false) => {
        let isValid = true
        if (saveLoading) {
            isValid = false
        }

        if(paymentCardField?.validate || forceValidation) {
            isValid = paymentCardField?.validate() || false
        }

        const intInstallments = parseInt(installments)
        if (plan == PLAN_MONTHLY && intInstallments != 1) {
            isValid = false
            setErrorInstallments(true)
            setHelperInstallments("O plano mensal não permite parcelamento")
        } else if (intInstallments >= 1 && intInstallments <= 12) {
            setErrorInstallments(false)
            setHelperInstallments(mandatoryField)
        } else {
            isValid = false
            setErrorInstallments(true)
            setHelperInstallments("A quantidade de parcelas é obrigatória")
        }

        const isChanged = plan != currentPlan || JSON.stringify(product) != JSON.stringify(currentProduct) || installments != currentInstallments || paymentCardField?.isEditField
        if (!isChanged) {
            isValid = false
            setErrorPlan(true)
            setHelperPlan("Não houve alteração na assinatura")
        } else {
            setErrorPlan(false)
            setHelperPlan()
        }

        return isValid
    }

    const isValid = () => paymentCardField?.isValid() && !errorPlan && !errorInstallments

    const fieldsWithErrorText = () => {
        const errors = (hasSubmited && paymentCardField?.fieldsWithErrorText()) || []
        if (saveLoading) {
            errors.push("Salvando a contratação")
        }
        if (errorPlan) {
            errors.push("Nenhuma alteração foi feita")
        }
        if (errorInstallments) {
            errors.push("Quantidade de parcelas")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate(true)) {
                if (!saveLoading) {
                    setSaveLoading(true)
                    const isPro = product?.find(code => code == PRONTUARIO_PRO)
                    const oldPro = currentProduct?.find(code => code == PRONTUARIO_PRO)
                    saveOnDataLayer({
                        event_action: `${isPro ? "prontuario-pro" : "assistente-vetsmart"}:${plan == PLAN_YEARLY ? "anual" : "mensal"}:botao:salvar-alteracoes`,
                        event_label: "editar-assinatura"
                    })
                    const {
                        payment,
                        isEditField,
                        cardData
                    } = paymentCardField
                    Parse.Cloud.run("payment", {
                        clinicId: clinic.objectId || clinic.id,
                        plan: plan,
                        products: product,
                        payment: {
                            vindiId: isEditField ? null : payment?.id,
                            type: "credit_card",
                            brand: cardData?.brand,
                            number: cardData?.cardNumber,
                            name: cardData?.cardName,
                            expires: cardData?.cardExpires,
                            cvv: cardData?.cardCVC,
                            installments: installments
                        }
                    })
                    .then(_subscription => {
                        const changed = []
                        const productWasChanged = JSON.stringify(product) != JSON.stringify(currentProduct)
                        if (productWasChanged) {
                            changed.push("product")
                        }
                        const planWasChanged = plan != currentPlan
                        if (planWasChanged) {
                            changed.push("plan")
                        }
                        if (isEditField) {
                            changed.push("payment")
                        }
                        const installmentsWasChanged = installments != currentInstallments
                        if (installmentsWasChanged) {
                            changed.push("installments")
                        }
                        saveOnTracker("Concluiu", "Assinatura", String(subscription?.id), "Alteração", null, null, null, {
                            oldPlan: currentPlan,
                            plan: plan,
                            oldProducts: currentProduct,
                            products: product,
                            oldInstallments: currentInstallments,
                            installments: parseInt(installments),
                            paymentChanged: isEditField ? true : false,
                            changed: changed
                        })
                        if (productWasChanged) {
                            const productHadPRO = currentProduct?.find(code => code == PRONTUARIO_PRO)
                            const productHasNoPRO = !product?.find(code => code == PRONTUARIO_PRO)
                            const downgrade = productHadPRO && productHasNoPRO ? true : false
                            saveOnDataLayer({
                                event: "avancou-editar-assinatura",
                                plano_anterior: currentProduct.join('+'),
                                plano_atual: product.join('+'),
                                downgrade: downgrade,
                                plano_anterior_value: oldPro ? (currentPlan == PLAN_YEARLY ? PRONTUARIO_PRO_YEARLY_TO : PRONTUARIO_PRO_MONTHLY) : (currentPlan == PLAN_YEARLY ? PRONTUARIO_AI_YEARLY_TO : PRONTUARIO_AI_MONTHLY),
                                plano_atual_value: isPro ? (plan == PLAN_YEARLY ? PRONTUARIO_PRO_YEARLY_TO : PRONTUARIO_PRO_MONTHLY) : (plan == PLAN_YEARLY ? PRONTUARIO_AI_YEARLY_TO : PRONTUARIO_AI_MONTHLY)
                            })
                        }
                        props.dispatch(getProntuarioPro(_subscription))
                        addEvent("Subscription__getList", {})
                        setSaveLoading(false)
                        return resolve(clinic)
                    })
                    .catch(error => {
                        setSaveLoading(false)
                        console.error(error)
                        Swal.fire({
                            icon: "error",
                            title: 'Desculpe',
                            text: error.code == 141 ? error.message : 'Não foi possível efetuar o pagamento, por favor, tente novamente',
                            footer: '<a href="https://pl-vetsmart.zendesk.com/hc/pt-br/categories/4410355279771-Prontu%C3%A1rio" target="_blank">Fale conosco</a>'
                        })
                        return reject()
                    })
                }
            } else {
                reject()
            }
        })
    }

    const isPro = (products) => {
        return products.find(code => code == PRONTUARIO_PRO)
    }

    useEffect(() => {
        validateOnTouch()
        if (plan == PLAN_MONTHLY && parseInt(installments) != 1) {
            setBackupInstallments(installments)
            setInstallments("1")
        }
        if (plan == PLAN_YEARLY && backupInstallments) {
            setInstallments(backupInstallments)
            setBackupInstallments(null)
        }
    }, [
        installments, plan, JSON.stringify(product),
        paymentCardField?.isEditField,
        paymentCardField?.payment?.id,
        paymentCardField?.cardData?.brand,
        paymentCardField?.cardData?.cardCVC,
        paymentCardField?.cardData?.cardExpires,
        paymentCardField?.cardData?.cardName,
        paymentCardField?.cardData?.cardNumber,
    ])

    useEffect(() => {
        if (features) {
            setCurrentPlan(features.planCode)
            setPlanTab(features.planCode)
            setPlan(features.planCode)
            const activeProducts = features.products?.filter(product => !product.canceled)?.map(product => product.code)?.sort()
            setCurrentProduct(activeProducts)
            setProduct(activeProducts)
            setCurrentInstallments(features?.subscription?.installments || "1")
            setInstallments(features?.subscription?.installments || "1")
        }
    }, [ features ])

    const errors = fieldsWithErrorText()

    const isUpgradePlan = currentPlan == PLAN_MONTHLY && plan != currentPlan
    const isUpgradeProduct = !isPro(currentProduct) && JSON.stringify(product) != JSON.stringify(currentProduct)
    const isUpgrade = isUpgradePlan || isUpgradeProduct

    return (
        <VSDrawer
            id="vspro-payment"
            width="50%"
            title="Editar Assinatura"
            cancel="Cancelar"
            submit={isValid ? "Salvar Alterações" : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            timeout={3000}
            onCancel={() => {
                saveOnDataLayer({
                    event_action: `editar-assinatura:botao:cancelar`,
                    event_label: `editar-assinatura`
                })
                history.goBack()
            }}
            onClose={() => {
                saveOnDataLayer({
                    event_action: `editar-assinatura:botao:fechar`,
                    event_label: `editar-assinatura`
                })
                history.goBack()
            }}
            onAfterSave={(object) => history.push("/configuracao-clinica/plano-e-cobranca")}
            VSDrawerSuccessProps={{
                title: "Alteração realizada com sucesso!"
            }} 
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar as alterações? ",
                confirm: "Cancelar Alterações",
                cancel: "Voltar para Edição do Cadastro"
            }}
        >
            <div className="row">
                <div className="col-12">
                    <h3>Assinatura</h3>
                    <div className="current-plan mb-3">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="current">
                                    Plano Atual
                                </div>
                            </div>
                            <div className="col">
                                <div className="name">
                                    { isPro(currentProduct) ? (
                                        <>Prontuário PRO</>
                                    ) : (
                                        <>
                                            Prontuário Básico<br/>
                                            + Assistente Vetsmart
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="amount">
                                    valor&nbsp;
                                    { isPro(currentProduct) ? (
                                        currentPlan == PLAN_MONTHLY ? (
                                            <>
                                                <b>{ formattedCurrency(PRONTUARIO_PRO_MONTHLY) }</b>/mês
                                            </>
                                        ) : (
                                            <>
                                                <b>{ formattedCurrency(PRONTUARIO_PRO_YEARLY_TO) }</b>/ano
                                            </>
                                        )
                                    ) : (
                                        currentPlan == PLAN_MONTHLY ? (
                                            <>
                                                <b>{ formattedCurrency(PRONTUARIO_AI_MONTHLY) }</b>/mês
                                            </>
                                        ) : (
                                            <>
                                                <b>{ formattedCurrency(PRONTUARIO_AI_YEARLY_TO) }</b>/ano
                                            </>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">Pago { currentPlan == PLAN_MONTHLY ? "mensalmente" : "anualmente" }, o plano será renovado em { dayjs(subscription?.next_billing_at || subscription?.start_at).format("LL") }</div>
                    <h3>Planos disponíveis</h3>
                    <div className="tabs mb-3">
                        <p className="plan text-center">
                            <b>Ganhe 20% de desconto</b> pagando anualmente
                        </p>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col pe-1">
                                        <div className={`col tab ${planTab == PLAN_MONTHLY ? "tab-selected" : ""}`} onClick={() => setPlanTab(PLAN_MONTHLY)}>
                                            Pago mensalmente
                                        </div>
                                    </div>
                                    <div className="col ps-1">
                                        <div className={`col tab ${planTab == PLAN_YEARLY ? "tab-selected" : ""}`} onClick={() => setPlanTab(PLAN_YEARLY)}>
                                            Pago anualmente
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="products" className="row g-3 mb-4">
                        { products.map((item, index) => (
                            <div className="col-12 col-lg" key={index}>
                                <div
                                    className={`card h-100 ${JSON.stringify(item.value) == JSON.stringify(currentProduct) && planTab == currentPlan ? "card-current" : (JSON.stringify(item.value) == JSON.stringify(product) && planTab == plan ? "card-selected" : "card-option")}`}
                                    onClick={() => {
                                        const checkNotCurrentProduct = JSON.stringify(item.value) != JSON.stringify(currentProduct) || planTab != currentPlan
                                        if (checkNotCurrentProduct) {
                                            const unCheckProduct = JSON.stringify(item.value) == JSON.stringify(product) && planTab == plan
                                            if (unCheckProduct) {
                                                setPlan(currentPlan)
                                                setProduct(currentProduct)
                                            } else {
                                                setPlan(planTab)
                                                setProduct(item.value)
                                            }
                                        }
                                    }}
                                >
                                    <div className="card-body">
                                        <h4>
                                            { item.title }
                                        </h4>
                                        <Accordion
                                            expanded={accordions?.[item.key] || false}
                                            onChange={() => setAccordions({
                                                ...accordions,
                                                [item.key]: !accordions?.[item.key]
                                            })}
                                        >
                                            <AccordionSummary
                                                expandIcon={<IconArrowLeft width={8} height={12} fill="var(--vsprontuario-secondary-color-dark)" style={{ transform: `rotate(${ accordions?.assistant ? "90deg" : "180deg" })` }} />}
                                            >
                                                { accordions?.[item.key] ? "Ocultar detalhes" : "Ver mais detalhes" }
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                { item.details.map((detail, index) => (
                                                    <div className="row gx-2 mb-2" key={index}>
                                                        <div className="col">{ detail }</div>
                                                    </div>
                                                )) }
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row g-3 mb-2">
                                            <div className="col-12">
                                                <div className={`pricing ${planTab}`}>
                                                    <div className="from">{ planTab == PLAN_MONTHLY ? "" : formattedCurrency(item.pricing.yearly.from) }</div>
                                                    <h2 className="amount"><small>{ planTab == PLAN_MONTHLY ? "" : "12x de " }R$</small> {formattedCurrency(planTab == PLAN_MONTHLY ? item.pricing.monthly.amount : item.pricing.yearly.installments)}</h2>
                                                    { planTab == PLAN_MONTHLY ? "mensal no cartão" : `ou ${formattedCurrency(item.pricing.yearly.amount)} à vista`}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="tag">
                                                    { JSON.stringify(item.value) == JSON.stringify(currentProduct) && planTab == currentPlan ? "PLANO ATUAL" : "SELECIONAR" }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                    <div className="vspro-upgrade-subtitle">FORMA DE PAGAMENTO</div>
                    <div className="vspro-upgrade-text mt-1">A cobrança será feita no cartão cadastrado</div>
                    <PaymentCardField
                        ref={PaymentCardFieldRef}
                        clinic={clinic}
                        features={features}
                        subscription={subscription}
                    />
                    { plan == PLAN_YEARLY && (
                        <div className="row g-3 mb-3 mt-2">
                            <div className="col-12">
                                <TextField
                                    placeholder="Selecione a quantidade de parcelas"
                                    label="Número de Parcelas"
                                    value={installments}
                                    onChange={({ target: { value } }) => setInstallments(value)}
                                    error={errorInstallments}
                                    helperText={helperInstallments}
                                    select
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    { getInstallmentsOfProductYearly(isPro(product) ? PRONTUARIO_PRO : PRONTUARIO_AI).map(_installment => (
                                        <MenuItem key={_installment.value} value={_installment.value}>
                                            { _installment.label }
                                        </MenuItem>
                                    )) }
                                </TextField>
                            </div>
                        </div>
                    )}
                    { errorPlan && (
                        <p className="text-danger text-center">
                            { helperPlan }
                        </p>
                    )}
                    { isUpgrade && (
                        <div className="row">
                            <div className="col-12">
                                <SubscriptionAlertView />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        features: state.features
    };
};

export default connect(mapStateToProps)(ProPaymentForm)