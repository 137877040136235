import React from "react";

import Step1 from "../../../assets/browsers/edge-step1.png"
import Step2 from "../../../assets/browsers/edge-step2.png"
import Step3 from "../../../assets/browsers/edge-step3.png"

const BrowserEdge = (props) => {
    return (
        <div id="edge">
            <h4>Passo a passo - Microsoft Edge</h4>
            <div className="row g-3 mb-3">
                <div className="col-12">
                    <b>Passo 1:</b> Abra o Prontuário do Vetsmart no Microsoft Edge 
                    e toque no ícone de três traços no canto inferior direito da tela, 
                    para usuários de Android.<br/>
                    No caso de usuários de iOS, clique no ícone inferior direito e 
                    clique em “Compartilhar”.
                </div>
                <div className="col-12">
                    <img src={Step1} width="100%" />
                </div>
                <div className="col-12">
                    <b>Passo 2:</b> No menu que abre, clique em “Adicionar ao telefone” 
                    ou “Adicionar à Tela de Início”.
                </div>
                <div className="col-12">
                    <img src={Step2} width="100%" />
                </div>
                <div className="col-12">
                    <b>Passo 3:</b> Na tela seguinte, clique em “Adicionar”.
                </div>
                <div className="col-12">
                    <img src={Step3} width="100%" />
                </div>
            </div>
        </div>
    )
}

export default BrowserEdge