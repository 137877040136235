import React, { useEffect, useState } from "react"

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CircularProgress, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"

import "./FirstSteps.scss"
import { addEvent, validatePhone, useWindowSize } from "../../utils";
import TextFieldMask from "../textFieldMask/TextFieldMask";
import axios from "axios";
import { Dialog } from "@mui/material";

import { ReactComponent as IconCheck } from "../../assets/icon-white-check.svg"
import { ACL } from "../../constants";

const useStyles = makeStyles((theme) => ({
    accordion: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        margin: '0 !important'
    },
    accordionSummary: {
        padding: 0,
        minHeight: 'initial !important',
        justifyContent: 'left',
        alignItems: 'flex-start'
    },
    accordionContent: {
        margin: '12px 0 !important',
        padding: 0,
        flexGrow: 'initial'
    },
    accordionIcon: {
        marginRight: "-5px"
    }
}))

export const STEP_CLINIC        = 1
export const STEP_OWNER         = 2
export const STEP_PATIENT       = 3
export const STEP_APPOINTMENT   = 4
export const STEP_VACCINE       = 5
export const STEP_SETTINGS      = 6
export const STEP_PRESCRIPTION  = 7
export const STEP_SIGNATURE     = 8
export const STEP_SHARE         = 9
export const TOTAL_STEPS        = 9

const FirstSteps = (props) => {

    const classes = useStyles();

    const {
        user,
        clinic,
        onboarding,
        history,
        alwaysOnDisplay,
        permissions,
        onChangeShowSteps
    } = props

    const {
        width
    } = useWindowSize()

    const isMenuMobile = width <= 768

    const [ sendingWhatsapp, setSendingWhatsapp ] = useState(false)
    const [ showSendWhatsapp, setShowSendWhatsapp ] = useState(false)
    const [ showSteps, setShowSteps ] = useState(false)
    const [ stepVisible, setStepVisible ] = useState(null)
    const [ progressPercent, setProgressPercent ] = useState(100)

    const [ phone, setPhone ] = useState('')
    const [ errorPhone, setErrorPhone ] = useState(false)
    const [ helperPhone, setHelperPhone ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const totalFinishedSteps = 1 + (onboarding.hasCreatedOwner ? 1 : 0) + (onboarding.hasCreatedPatient ? 1 : 0) + (onboarding.hasCreatedAppointment ? 1 : 0) + (onboarding.hasCreatedVaccine ? 1 : 0) + (onboarding.hasSetupClinicPrescription || !clinic.isOwner ? 1 : 0) + (onboarding.hasCreatedPrescription ? 1 : 0) + (onboarding.hasKnowMoreAboutDigitalSignature ? 1 : 0) + (onboarding.hasCreatedPrescriptionShare ? 1 : 0)
    let steps = [
        {
            status: 'previous',
            title: 'Criar prontuário',
            text: '',
            children: null
        },
        {
            status: onboarding.hasCreatedOwner ? 'previous' : 'next',
            title: 'Cadastre um tutor',
            text: 'Para começar a usar o Prontuário, cadastre um tutor.',
            children : (
                <button className="btn-step" onClick={() => {
                    history.push(`/tutor`)
                    setShowSteps(false)
                    addEvent("Tour__startTour", { step: STEP_OWNER })
                }}>
                    Cadastrar novo tutor
                </button>
            )
        },
        {
            status: onboarding.hasCreatedPatient ? 'previous' : 'next',
            title: 'Cadastre um animal',
            text: onboarding.hasOwner ? 'Você cadastrou um tutor. Agora cadastre um animal.' : 'Cadastre um tutor para depois adicionar seus animais.',
            children : (
                <button
                    className="btn-step d-block"
                    disabled={!onboarding.hasOwner}
                    onClick={() => {
                        history.push(`/tutor`)
                        setShowSteps(false)
                        addEvent("Tour__startTour", { step: STEP_PATIENT })
                    }}
                >
                    Cadastrar novo animal
                </button>
            )
        },
        {
            status: onboarding.hasCreatedAppointment ? 'previous' : 'next',
            title: 'Adicione uma consulta',
            text: onboarding.hasPatient ? 'Você cadastrou um animal. Agora adicione uma consulta.' : 'Cadastre um animal para depois adicionar uma consulta.',
            children : (
                <button
                    className="btn-step"
                    disabled={!onboarding.hasPatient}
                    onClick={() => {
                        history.push(`/animal`)
                        setShowSteps(false)
                        addEvent("Tour__startTour", { step: STEP_APPOINTMENT })
                    }}
                >
                    Adicionar nova consulta
                </button>
            )
        },
        {
            status: onboarding.hasCreatedVaccine ? 'previous' : 'next',
            title: 'Aplique uma vacina',
            text: (
                <>
                    <div>{onboarding.hasPatient ? 
                        'Adicione uma vacina e programe a próxima dose.' : 
                        'Cadastre um animal para depois adicionar uma vacina.'}</div>
                </>
            ),
            children: (
                <button
                    className="btn-step"
                    disabled={!onboarding.hasPatient}
                    onClick={() => {
                        history.push(`/animal`)
                        setShowSteps(false)
                        addEvent("Tour__startTour", { step: STEP_VACCINE })
                    }}
                >
                    Adicionar nova vacina
                </button>
            )
        },
        ...(clinic.isOwner ? [{
            status: onboarding.hasSetupClinicPrescription ? 'previous' : 'next',
            title: 'Configure seu modelo de receituário',
            text: 'Customize a receita que o tutor receberá após o atendimento.',
            children : (
                <button
                    className="btn-step"
                    onClick={() => {
                        history.push(`/configuracao-clinica`)
                        setShowSteps(false)
                        addEvent("Tour__startTour", { step: STEP_SETTINGS })
                    }}
                >
                    Configurar receituário
                </button>
            )
        }] : []),
        {
            status: onboarding.hasCreatedPrescription ? 'previous' : 'next',
            title: 'Faça uma prescrição',
            text: onboarding.hasPatient ? 'Com o receituário configurado, faça uma prescrição.' : 'Cadastre um animal para depois cadastrar uma prescrição.',
            children : (
                <button
                    className="btn-step"
                    disabled={!onboarding.hasPatient}
                    onClick={() => {
                        history.push(`/animal`)
                        setShowSteps(false)
                        addEvent("Tour__startTour", { step: STEP_PRESCRIPTION })
                    }}
                >
                    Adicionar nova prescrição
                </button>
            )
        },
        {
            status: onboarding.hasKnowMoreAboutDigitalSignature ? 'previous' : 'next',
            title: 'Assine suas receitas digitalmente',
            text: 'Você pode utilizar um certificado digital para assinar suas prescrições e enviar apenas a via digital por SMS, e-mail ou WhatsApp ao seu tutor(a), sem precisar imprimir.',
            children : (
                <a
                    className="btn-step d-block"
                    target="_blank"
                    href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/12476571256475"
                    onClick={() => {
                        addEvent("TourDigitalSignature__knowMore", {})
                    }}
                >
                    Saiba mais sobre assinatura
                </a>
            )
        },
        {
            status: onboarding.hasCreatedPrescriptionShare ? 'previous' : 'next',
            title: 'Veja como o tutor recebe a prescrição digital',
            text: onboarding.hasPrescription ? 'Insira seu número de WhatsApp para receber a prescrição como se fosse o tutor.' : 'Adicione uma prescrição para o último animal para poder enviá-la.',
            children: (
                onboarding.hasPrescription ? (
                    <React.Fragment>
                        <div className="row mt-2 mb-3">
                            <div className="col-12">
                                <TextFieldMask
                                    type="tel"
                                    mask={phone?.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                    value={phone}
                                    disabled={onboarding.hasCreatedPrescriptionShare}
                                    onAccept={(value) => setPhone(value)}
                                    className="w-100"
                                    placeholder="(00) 00000-0000"
                                    label="Número de WhatsApp"
                                    error={errorPhone}
                                    helperText={helperPhone}
                                />
                            </div>
                            <div className="col-12 pb-2">
                                <button
                                    className="btn-step"
                                    disabled={onboarding.hasCreatedPrescriptionShare}
                                    onClick={() => {
                                        if (validate()) {
                                            setSendingWhatsapp(true)
                                            setShowSendWhatsapp(true)
                                            const lastPrescription = onboarding.lastPrescription
                                            axios.post(
                                                `${process.env.REACT_APP_API_V2}/sendWhatsapp`,
                                                {
                                                    user: user.objectId,
                                                    clinic: clinic.objectId,
                                                    owner: lastPrescription.get("patient").get("owner").id,
                                                    patient: lastPrescription.get("patient").id,
                                                    createdFromClass: "Prescription",
                                                    createdFromId: lastPrescription.id,
                                                    toHTML: 1,
                                                    messageTemplateId: `WHATSAPP_PRESCRIPTION${clinic?.store?.url ? "_STORE" : ""}`,
                                                    sentTo: phone
                                                }
                                            )
                                            .then(_ => {
                                                setSendingWhatsapp(false)
                                            })
                                            .catch(error => {
                                                setSendingWhatsapp(false)
                                                console.error(error)
                                                Swal.fire(
                                                    'Desculpe',
                                                    `Ocorreu algum erro ao tentar enviar a mensagem`,
                                                    'error'
                                                )
                                            })
                                        }
                                    }}
                                >
                                    Enviar prescrição
                                </button>
                            </div>
                        </div>
                        <Dialog open={showSendWhatsapp}>
                            <DialogContent>
                                <div className="row text-center">
                                    { sendingWhatsapp ? (
                                        <React.Fragment>
                                            <div className="col-12 mb-3">
                                                <CircularProgress color="secondary" />
                                            </div>
                                            <div className="col-12 mb-3">
                                                Carregando WhatsApp
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="col-12 mb-3">
                                                <div className="icon-circle">
                                                    <IconCheck width="12px" height="9px" />
                                                </div>
                                            </div>
                                            <div className="col-12 mb-3">
                                                WhatsApp enviado
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div className="col-12 mb-3">
                                        <button
                                            className="btn-outline-submit"
                                            disabled={sendingWhatsapp}
                                            onClick={() => {
                                                setShowSendWhatsapp(false)
                                                addEvent("TourShare__sendYourFirstDocumentByWhatsapp", {})
                                            }}
                                        >
                                            Fechar
                                        </button>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </React.Fragment>
                ) : null
            )
        },
    ]

    const validate = () => {
        let isValid = true

        if (validatePhone(phone)) {
            setErrorPhone(false)
            setHelperPhone(<span>Campo <b>Obrigatório</b></span>)
        } else {
            isValid = false
            setErrorPhone(true)
            setHelperPhone("O número informado não é válido")
        }

        return isValid
    }
    
    useEffect(() => {
        if (onboarding && onboarding.object) {
            setProgressPercent(onboarding.progressStep)
            setStepVisible(onboarding.currentStep)
        }
    }, [ onboarding ])

    useEffect(() => {
        const onCloseSteps = () => {
            setShowSteps(false)
        }
        document.addEventListener("FirstSteps__close", onCloseSteps)
        return () => {
            document.removeEventListener("FirstSteps__close", onCloseSteps)
        }
    })

    useEffect(() => {
        onChangeShowSteps &&
        onChangeShowSteps(showSteps)
    }, [ showSteps ])

    return permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT) &&
        permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) &&
        permissions?.check(ACL.PRESCRIPTION, ACL.Rule.EDIT) ? (
        <React.Fragment>
            { !alwaysOnDisplay && !onboarding.hasCompleted && (
                <div className={`menu-item step ${showSteps ? 'selected' : ''}`} onClick={() => setShowSteps(!showSteps)}>
                    <div className="row">
                        <div className="col-12">
                            <span>Primeiros passos</span>
                            <div className="step-progress">
                                <div className="step-progress-status" style={{ width: `${progressPercent}%` }}></div>
                            </div>
                            <svg viewBox="0 0 36 36" className="step-progress-circle">
                                <path className="circle-bg"
                                    d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <path className="circle"
                                    strokeDasharray={`${progressPercent}, 100`}
                                    d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <text x="18.5" y="21.35" className="percentage">{ progressPercent }%</text>
                            </svg>
                        </div>
                    </div>
                </div>
            )}
            { !onboarding.hasCompleted && (alwaysOnDisplay || showSteps) && (
                <div className="step-area no-scrollbar">
                    <div className="step-close" onClick={() => setShowSteps(false)}>
                        <IconClose width="24" height="24" />
                    </div>
                    <div className="step-title">
                        Primeiros passos    
                    </div>
                    <div className="steps">
                        { steps.map((step, index) => (
                            <div className="step-item" key={index}>
                                <Accordion
                                    classes={{
                                        root: classes.accordion
                                    }}
                                    expanded={stepVisible === (index + 1) && step.text}
                                    onChange={() => step.text && setStepVisible(stepVisible === (index + 1) ? null : (index + 1))}
                                >
                                    <AccordionSummary
                                        classes={{
                                            root: classes.accordionSummary,
                                            content: classes.accordionContent,
                                            expandIcon: classes.accordionIcon
                                        }}
                                        expandIcon={index == 0 ? null : <IconArrowLeft className="step-icon" width="8px" height="12px" style={{ transform: 'rotate(270deg)' }} />}
                                    >
                                        <div className="row">
                                            <div className="col-auto pe-0">
                                                <div className={`step-count step-${index + 1 == stepVisible ? "current" : step.status}`}>
                                                    { (index + 1) }
                                                </div>
                                            </div>
                                            <div className="col step-accordion">
                                                { step.title }
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="step-content">
                                            { step.text }
                                            { step.children }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )) }
                    </div>
                    <div className="pb-5 mb-5">
                        <button
                            className="btn-step"
                            disabled={onboarding.progressStep != 100}
                            onClick={() => addEvent("Tour__hasCompleted", {})}
                        >
                            Concluir Primeiros passos
                        </button>
                    </div>
                </div>
            )}
        </React.Fragment>
    ) : <></>;
}

const mapStateToProps = state => {
    return {
		user: state.user,
		clinic: state.clinic,
		onboarding: state.onboarding,
		permissions: state.permissions
    };
};

export default connect(mapStateToProps)(withRouter(FirstSteps));