import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';
import { CircularProgress, InputAdornment } from '@mui/material';

import { ReactComponent as IconAddO } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"
import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"

import { 
    diacriticSensitive
} from '../../utils'

import BoxOwner from '../owner/BoxOwner';
import VSEmpty from '../../components/vsEmpty/VSEmpty';
import BoxPatient from './BoxPatient';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';

import './PatientSelectQuery.scss'
const PatientSelectQuery = (props) => {

    const {
        state
    } = useLocation()

    const {
        text,
        clinic,
        history,
        getNextRoute,
        proxima,
        selectPatientLabel,
        boxPatientCallOnSelectByBoxClick,
        appendContentToBoxPatient,
        includePatientHistory
    } = props

    const searchWithDelay = useRef(null)

    const [ query, setQuery ] = useState("")
    const [ owners, setOwners ] = useState([])
    const [ patients, setPatients ] = useState([])
    const [ histories, setHistories ] = useState({})
    const [ hasPatient, setHasPatient ] = useState(true)
    const [ loading, setLoading ] = useState(false)

    const findOwners = () => {
        return new Promise((resolve, reject) => {
            let queryByName = new Parse.Query("Owner")
            queryByName.matches("name", diacriticSensitive(query), "im")

            let queryByCpf = new Parse.Query("Owner")
            queryByCpf.matches("cpf", diacriticSensitive(query), "im")

            let queryByPhone = new Parse.Query("Owner")
            queryByPhone.matches("phone", diacriticSensitive(query), "im")

            const mainQuery = Parse.Query.or(
                queryByName,
                queryByCpf,
                queryByPhone
            )
            mainQuery.equalTo("clinic", clinic.object);
            mainQuery.equalTo("isDeleted", false);
            mainQuery.ascending("name");
            mainQuery.find()
            .then(owners => {
                resolve(owners)
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const findPatients = () => {
        return new Promise((resolve, reject) => {
            const mainQuery = new Parse.Query("Patient")
            mainQuery.equalTo("clinic", clinic.object);
            mainQuery.equalTo("isDeleted", false);
            mainQuery.matches("name", diacriticSensitive(query), "im")
            mainQuery.ascending("name");
            mainQuery.include("owner");
            mainQuery.find()
            .then(patients => {
                if(!includePatientHistory){
                    resolve(patients)
                } else {
                    const queryHistory = new Parse.Query("History");
                    queryHistory.equalTo("clinic", clinic.object);
                    queryHistory.equalTo("isDeleted", false);
                    queryHistory.containedIn("patient", patients);
                    queryHistory.limit(100000000)
                    queryHistory.find()
                    .then(_histories => {
                        let historiesByPatient = []
                        _histories.map(history => {
                            if (!historiesByPatient[history.get('patient').id]) {
                                historiesByPatient[history.get('patient').id] = []
                            }
                            historiesByPatient[history.get('patient').id].push(history)
                        })
                        setHistories(historiesByPatient)
                        resolve(patients)
                    })
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const onLoadOwnerPatients = ownerPatients => {
        const allPatients = (patients && patients.length > 0 ? patients : []).concat(ownerPatients && ownerPatients.length > 0 ? ownerPatients : [])

        const queryHistory = new Parse.Query("History");
        queryHistory.equalTo("clinic", clinic.object);
        queryHistory.equalTo("isDeleted", false);
        queryHistory.containedIn("patient", allPatients);
        queryHistory.limit(100000000)
        queryHistory.find()
        .then(_histories => {
            let historiesByPatient = []
            _histories.map(history => {
                if (!historiesByPatient[history.get('patient').id]) {
                    historiesByPatient[history.get('patient').id] = []
                }
                historiesByPatient[history.get('patient').id].push(history)
            })
            setHistories(historiesByPatient)
        })
    }

    useEffect(() => {
        searchWithDelay.current = setTimeout(() => {
            setLoading(true)
            setOwners([])
            setPatients([])
            if (query) {
                Promise.all([ findOwners(), findPatients() ])
                .then(([ _owners, _patients ]) => {
                    setOwners(_owners)
                    setPatients(_patients)
                    setLoading(false)
                })
            }
        }, 300)
        return () => {
            clearInterval(searchWithDelay.current)
        }
    }, [ query ])

    useEffect(() => {
        if (clinic && clinic.object) {
            setLoading(true)
            const patientTotal = new Parse.Query("Patient");
            patientTotal.equalTo("clinic", clinic.object);
            patientTotal.equalTo("isDeleted", false);
            patientTotal.count()
            .then(_total => {
                setHasPatient(_total > 0)
                setLoading(false)
            })
            .catch(error => console.error(error))
        }
    }, [ clinic ])

    return (
        <div className="vssearch">
            <div className="vssearch-area">
                <TextField
                    className="vssearch-input"
                    placeholder="Buscar por Animal ou um(a) Tutor(a) pelo Nome, CPF ou Telefone"
                    variant="outlined"
                    size="small"
                    value={query}
                    onChange={({ target: { value} }) => setQuery(value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <IconSearch width="20px" height="20px" fill="#888888" />
                        </InputAdornment>,
                    }}
                    fullWidth
                />
            </div>
            { hasPatient ? (
                query ? (
                    loading ? (
                        <div className="mt-4 data-loading">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="vssearch-list">
                            <div className="row pt-2">
                                <div className="col-12">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(expanded) => (
                                            `Tutores (${ owners.length })`
                                        )}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                { owners && owners.length > 0 ? (
                                                    owners.map((owner, index) => (
                                                        <BoxOwner
                                                            key={index}
                                                            owner={owner}
                                                            history={history}
                                                            showAll={true}
                                                            hideContactInfo
                                                            hideIcon
                                                            boxPatientProps={{
                                                                callOnSelectByBoxClick: boxPatientCallOnSelectByBoxClick,
                                                                selectPatientLabel: selectPatientLabel,
                                                                appendContent: patient => appendContentToBoxPatient ? appendContentToBoxPatient(patient, histories ? histories[patient.id] : undefined) : undefined
                                                            }}
                                                            onSelectPatient={(patient) => getNextRoute(patient, "BoxOwner")}
                                                            onNewPatient={() => history.replace(`/tutor/${owner.id}/animal/novo?proxima=${proxima}`, {
                                                                ...state
                                                            })}
                                                            handleOwnerPatients={onLoadOwnerPatients}
                                                        />
                                                    ))
                                                ) : (
                                                    <div className="vssearch-empty">
                                                        Nenhum(a) Tutor(a) Encontrado(a)
                                                    </div>
                                                )}
                                                <hr/>
                                                <button
                                                    className="vssearch-new"
                                                    onClick={() => history.replace(`/tutor/form/novo?proxima=${proxima}&name=${query}`, {
                                                        ...state
                                                    })}
                                                >
                                                    Cadastrar um(a) Novo(a) Tutor(a)
                                                    <IconArrowLeft width="16px" height="16px" className="mt-1" />
                                                </button>
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(expanded) => (
                                            `Animais (${ patients.length })`
                                        )}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                { patients && patients.length > 0 ? (
                                                    patients.map((patient, index) => (
                                                        <BoxPatient
                                                            key={index}
                                                            patient={patient}
                                                            history={history}
                                                            actions={(
                                                                <div className="col-auto">
                                                                    <button className="vsbox-btn vsbox-btn-primary" onClick={() => getNextRoute(patient, "BoxPatient")}>
                                                                        {selectPatientLabel ? selectPatientLabel : "SELECIONAR ANIMAL"}
                                                                    </button>
                                                                </div>
                                                            )}
                                                            appendContent={() => appendContentToBoxPatient ? appendContentToBoxPatient(patient, histories ? histories[patient.id] : undefined) : undefined}
                                                        />
                                                    ))
                                                ) : (
                                                    <div className="vssearch-empty">
                                                        Nenhum Animal Encontrado
                                                    </div>
                                                )}
                                                <hr/>
                                                <button
                                                    className="vssearch-new"
                                                    onClick={() => history.replace(`/tutor/selecionar/animal/novo?proxima=${proxima}&name=${query}`, {
                                                        ...state
                                                    })}
                                                >
                                                    Cadastrar Novo Animal
                                                    <IconArrowLeft width="16px" height="16px" className="mt-1" />
                                                </button>
                                                <hr/>
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="vssearch-default">
                        { text }
                    </div>
                )
            ) : (
                <VSEmpty
                    icon={<IconUserO width="32px" height="32px" />}
                    title="Você ainda não possui nenhum animal cadastrado no Prontuário"
                    text="Cadastre o primeiro animal e comece a aproveitar todos os benefícios de gestão de clientes do Prontuário Vetsmart"
                >
                    <button
                        id="new-owner-link"
                        className="vsbox-btn vsbox-btn-primary"
                        onClick={() => history.push(`/tutor/selecionar/animal/novo`)}
                    >
                        <IconAddO width="14" height="14" className="me-2" />
                        Cadastrar novo animal
                    </button>
                </VSEmpty>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        onboarding: state.onboarding
    };
};
  
export default connect(mapStateToProps)(PatientSelectQuery);