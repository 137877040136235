import React from 'react'

import { saveOnTracker } from '../../utils';
import { ReactComponent as IconClinicFree } from "../../assets/icon-clinic-free.svg"

const HomeLimit = (props) => {

    const {
        history
    } = props

    return (
        <div className="vsbox card-pro-advertising">
            <div className="vsbox-body">
                <div className="row align-items-center mb-2">
                    <div className="col">
                        <div className="vsbox-title mb-1">Você está usando o Prontuário Básico</div>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-auto">
                        <IconClinicFree style={{borderRadius: 10}} />
                    </div>
                    <div className="col">
                        <div className="mb-2">O que te dá direito a degustar algumas funcionalidades do PRO limitadas.</div>
                        <a 
                            style={{ 
                                cursor: "pointer",
                                fontSize: "14px",
                                fontWeight: 600
                            }} 
                            onClick={() => {
                                history.push("/limites")
                                saveOnTracker("Clicou", "Banner", "Limites do Prontuário gratuito", "Saiba o quanto você já usou")
                            }}
                        >
                            Saiba o quanto você já usou
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
  
export default HomeLimit;