import React from "react";
import { ReactComponent as IconHeaderIA } from "../../assets/icon-header-ia.svg"

import "./PlanBadge.scss"

const PlanBadge = props => {
    const {
        features
    } = props

    return (
        <span className="plan-badge">
            {features?.pro ? ( 
                <span className="badge rounded-pill bg-secondary">
                    PRO
                    {features?.ai &&
                    <IconHeaderIA className="ms-1" width="12px" height="12px" fill="#FFFFFF" />}
                </span>
            ) : 
            features?.ai ? (
                <span className="badge rounded-pill" style={{ background:"var(--vsprontuario-primary-color)" }}>
                    ASSISTENTE
                    <IconHeaderIA className="ms-1" width="12px" height="12px" fill="#FFFFFF" />
                </span>
            ) : (
                <span className="badge rounded-pill" style={{ background:"var(--vsprontuario-primary-color)" }}>BÁSICO</span>
            )}
        </span>
    )
}

export default PlanBadge