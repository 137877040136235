import { useEffect, useState } from "react"
import { connect } from "react-redux"

import { Chip, CircularProgress } from "@mui/material"

import VSProgress from "../../components/vsProgress/VSProgress"
import VSTooltip from "../../components/vsTooltip/VSTooltip"
import VSPagination from "../../components/vsPagination/VSPagination"
import CardProAdvertising from "../../components/cardProAdvertising/CardProAdvertising"
import CardAssistant from "../virtualAssistant/CardAssistant"

import { ReactComponent as IconInfoO } from "../../assets/icon-primary-info-o.svg"

import { historyDocument } from "../patient/tabs/history/History"
import { dayjs } from "../../utils"
import { getPatientLimit } from "../patient/Patient"

import "./Limit.scss"
const Limit = (props) => {

    const {
        features,
        clinic,
        history
    } = props

    const rowsPerPage = 15

    const [ period, setPeriod ] = useState(null)
    const [ loadingMessagesLimit, setLoadingMessagesLimit ] = useState(true)
    const [ messagesLimit, setMessagesLimit ] = useState([])
    const [ loadingMessages, setLoadingMessages ] = useState(false)
    const [ messages, setMessages ] = useState([])
    const [ page, setPage ] = useState(1)
    const [ total, setTotal ] = useState(0)

    const [ loadingPatientLimit, setLoadingPatientLimit ] = useState(true)
    const [ patientLimit, setPatientLimit ] = useState(null)

    const getMessagesLimit = () => {
        setLoadingMessagesLimit(true)

        Parse.Cloud.run("checkMessageLimit", {
            clinicId: clinic.objectId,
            templates: []
        })
        .then(({ startAt, endAt, limits }) => {
            setMessagesLimit(limits)
            setPeriod({
                startAt: startAt,
                endAt: endAt,
            })
            setLoadingMessagesLimit(false)
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getPatientsLimit = () => {
        setLoadingPatientLimit(true)

        console.log(clinic)

        getPatientLimit(clinic.object)
        .then(limit => {
            if (limit) {
                setPatientLimit(limit)
            }
            setLoadingPatientLimit(false)
        })
    }

    const getMessages = () => {
        setLoadingMessages(true)
        var templates = []
        messagesLimit.map(limit => limit.templates.map(template => templates.push(template)))

        const query = new Parse.Query("VW_Message")
        query.equalTo("clinic", clinic.objectId)
        query.equalTo("isDeleted", false)
        query.equalTo("sentFrom", "Vet Smart")
        query.greaterThanOrEqualTo("createdAt", period.startAt)
        query.lessThanOrEqualTo("createdAt", period.endAt)
        query.containedIn("messageTemplateId", templates)
        query.descending("createdAt")
        query.addAscending("objectId")
        query.include("createdFromObject")
        query.include("createdFromObject.patient")
        query.skip((page - 1) * rowsPerPage)
        query.limit(rowsPerPage)
        query.withCount()
        query.find()
        .then(({ results, count }) => {
            setMessages(results)
            setTotal(count)
            setLoadingMessages(false)
        })
        .catch(error => {
            console.error(error)
            setLoadingMessages(false)
        })
    }

    const calculateUsagePercentage = (limit) => {
        const percent = limit.used * 100 / limit.total
        return percent > 100 ? 100 : percent
    }

    useEffect(() => {
        if (period?.startAt && period.endAt) {
            getMessages()
        }
    }, [ period, page ])

    useEffect(() => {
        if (clinic && clinic.object && clinic.isLoaded) {
            getMessagesLimit()
            getPatientsLimit()
        }
    }, [ clinic ])

    return (
        <div id="message-limit">
            <div className="page-content">
                <div className="row g-3">
                    <div className="col-12">
                        <div className="page-title">
                            Limites do Prontuário Básico
                        </div>
                        {!clinic.isOwner &&
                        <div className="contact-to-owner-advice mt-3">
                            Entre em contato com o <b>Dono da Clínica</b> para contratar os serviços do Prontuário PRO.
                        </div>}
                    </div>
                    <div className={`col-12 ${clinic.isOwner ? "col-md-8" : ""}`}>
                        <div className="vsbox">
                            <div className="vsbox-body">
                                <div className="row g-3">
                                    { loadingPatientLimit && loadingMessagesLimit ? (
                                        <div className="data-loading">
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <>
                                            { loadingPatientLimit ? (
                                                <div className="data-loading">
                                                    <CircularProgress />
                                                </div>
                                            ) : patientLimit.isLimited && (
                                                <>
                                                    <div className="col-12">
                                                        <div className="limit-title limit-chip">
                                                            Saldos e limites disponíveis
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="limit-box">
                                                            <div className="limit-title">
                                                                Limite de Cadastros
                                                            </div>
                                                            <div className="limit-box-text">
                                                                { patientLimit.total } cadastros de animais
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <p className="mb-0">
                                                            Com o <b>plano Básico</b> ou <b>Básico + Assistente</b>, você pode aproveitar algumas funcionalidades do PRO de forma limitada. O limite para o cadastro de animais é de <b>{ patientLimit.total } cadastros</b>. Ao atingir esse limite, você poderá excluir cadastros antigos para liberar espaço ou realizar um upgrade para o plano PRO, que oferece cadastro ilimitado.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 mt-5">
                                                        <div className="row g-3 mb-5">
                                                            <div className="col-12 col-md-auto pe-md-4">
                                                                <VSProgress value={calculateUsagePercentage(patientLimit)}>
                                                                    { patientLimit.used }/{ patientLimit.total }
                                                                </VSProgress>
                                                            </div>
                                                            <div className="col-12 col-md">
                                                                <div className="mb-2">
                                                                    <b>
                                                                        Cadastro de animais
                                                                    </b>
                                                                </div>
                                                                <div>Limite Total: { patientLimit.total }</div>
                                                                <div>Limite Utilizado: { patientLimit.used }</div>
                                                                <div>Disponível: { patientLimit.available }</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            { loadingMessagesLimit ? (
                                                <div className="data-loading">
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="col-12">
                                                        <div className="limit-title limit-chip">
                                                            Saldos e limites com renovação mensal
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="limit-box">
                                                            <div className="limit-title">
                                                                Renovação do limite
                                                            </div>
                                                            <div className="limit-box-text">
                                                                Os limites das funcionalidades abaixo são renovados automaticamente no primeiro dia de cada mês.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <p className="mb-0">
                                                            Com o <b>plano Básico</b> ou <b>Básico + Assistente</b>, você pode experimentar algumas funcionalidades do PRO de forma limitada. No <b>plano PRO</b>, esses recursos estão disponíveis sem restrições, permitindo o uso completo e ilimitado.
                                                        </p>
                                                    </div>
                                                    { messagesLimit.length > 0 && (
                                                        <div className="col-12 mt-5">
                                                            { messagesLimit.map((limit, index) => (
                                                                <div key={index} className="row g-3 mb-5">
                                                                    <div className="col-12 col-md-auto pe-md-4">
                                                                        <VSProgress value={calculateUsagePercentage(limit)}>
                                                                            { limit.unavailable }/{ limit.total }
                                                                        </VSProgress>
                                                                    </div>
                                                                    <div className="col-12 col-md">
                                                                        <div className="mb-2">
                                                                            <b>
                                                                                { limit.templates[0]?.includes("PRESCRIPTION") ? (
                                                                                    "Envio de cópia Digital de Prescrição"
                                                                                ) : limit.templates[0]?.includes("EXAM_REQUEST") ? (
                                                                                    "Envio de cópia Digital de Solicitação de Exame"
                                                                                ) : limit.templates[0]?.includes("VACCINE_REMINDER") ? (
                                                                                    "Cadastros de Lembrete de vacina para o Tutor"
                                                                                ) : null}
                                                                            </b>
                                                                            { limit.templates[0]?.includes("VACCINE_REMINDER") && (
                                                                                <VSTooltip
                                                                                    placement="bottom-start"
                                                                                    title="Serão contabilizados os cadastros de Lembretes de vacina para o Tutor, pois o seu envio do lembrete pode ser agendado para uma data futura."
                                                                                >
                                                                                    <IconInfoO className="ms-1" width="16" height="16" fill="var(--vsprontuario-gray-color)" />
                                                                                </VSTooltip>
                                                                            )}
                                                                        </div>
                                                                        <div>Limite Total: { limit.total }</div>
                                                                        <div>Limite Utilizado: { limit.unavailable }</div>
                                                                        <div>Disponível: { limit.available > 0 ? limit.available : 0 }</div>
                                                                    </div>
                                                                </div>
                                                            )) }
                                                        </div>
                                                    )}
                                                    <div className="col-12">
                                                        <div><b>Detalhamento:</b></div>
                                                    </div>
                                                    { loadingMessages ? (
                                                        <div className="data-loading">
                                                            <CircularProgress />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            { messages.length > 0 ? (
                                                                <>
                                                                    { messages.map((message, index) => {
                                                                        const object = message.get("createdFromObject")
                                                                        const patient = object.get("patient")
                                                                        const document = historyDocument(object)
                                                                        return (
                                                                            <div key={index} className="col-12 mt-2">
                                                                                <div className="limit-box text-start">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col">
                                                                                            <b>{ dayjs(message.get("createdAt")).format("DD/MM/YYYY [às] HH:mm") }</b> - <a onClick={() => history.push(document.share || document.edit)}>{ document.document }</a> para { patient.get("name") } via { message.get("messageType")?.name }
                                                                                        </div>
                                                                                        { message.get("error") && (
                                                                                            <div className="col-auto">
                                                                                                <span className="badge rounded-pill bg-danger ms-2">ERRO</span>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) }
                                                                    <div className="col-12">
                                                                        <VSPagination
                                                                            currentPage={page}
                                                                            rowsPerPage={rowsPerPage}
                                                                            totalRows={total}
                                                                            onChange={(_currentPage) => {
                                                                                setPage(_currentPage)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 mt-2">
                                                                    <div className="limit-box text-start">
                                                                        Nenhuma mensagem dos tipos acima foi enviada 
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    { clinic.isOwner && (
                        <div className="col-12 col-md-4">
                            <CardProAdvertising clinic={clinic} features={features} history={history} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(Limit)