import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import { CircularProgress } from '@mui/material';

import { ReactComponent as IconIA } from "../../assets/icon-header-ia.svg"
import { ReactComponent as IconIAService } from "../../assets/icon-ia-service.svg"
import { ReactComponent as IconFinancial } from "../../assets/icon-financial.svg"
import { ReactComponent as IconFeatures } from "../../assets/icon-features.svg"
import { ReactComponent as IconUserIA } from "../../assets/icon-user-ia.svg"
import { ReactComponent as IconArrowRight } from "../../assets/icon-arrow-right.svg"

import { addEvent, fixDecimalJavascript, formattedCurrency, getProductsAmount, saveOnDataLayer } from '../../utils';
import { PLAN_MONTHLY, PLAN_YEARLY, PRONTUARIO_AI, PRONTUARIO_AI_MONTHLY_UPSELL, PRONTUARIO_AI_NAME, PRONTUARIO_AI_YEARLY_UPSELL_12X, PRONTUARIO_LEGACY_PRO_MONTHLY, PRONTUARIO_LEGACY_PRO_YEARLY_12X, PRONTUARIO_LEGACY_PRO_YEARLY_TO, PRONTUARIO_PRO, PRONTUARIO_PRO_MONTHLY_UPSELL, PRONTUARIO_PRO_NAME, PRONTUARIO_PRO_YEARLY_UPSELL_12X } from '../../constants';
import { getProntuarioPro } from '../../store/actions';

import VSDrawer, { STATUS_CANCEL, STATUS_DEFAULT } from '../../components/vsDrawer/VSDrawer';
import PaymentCardField from '../../components/paymentCardField/PaymentCardField';
import SubscriptionAlertView from './SubscriptionAlertView';

import './ProUpgradeForm.scss'
const ProUpgradeForm = (props) => {

    const {
        features,
        clinic,
        history
    } = props

    const PaymentCardFieldRef = useRef(null)

    const productToUpsell = features?.upsellPro ? "prontuario-pro" : "assistente-vetsmart"

    const [ subscription, setSubscription ] = useState(null)
    const [ showProrata, setShowProrata ] = useState(false)

    const [ loading, setLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const validateOnTouch = () => {
        if (hasSubmited) {
            // PaymentCardFieldRef?.current?.validateOnTouch()
            validate()
        }
    }

    const validate = () => {
        let isValid = true

        if(PaymentCardFieldRef?.current?.validate && PaymentCardFieldRef?.current?.isEditField){
            isValid = PaymentCardFieldRef?.current?.validate()
        }

        return isValid
    }

    const isValid = () => (PaymentCardFieldRef?.current?.isValid && PaymentCardFieldRef?.current?.isValid()) || !PaymentCardFieldRef?.current?.isEditField

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a contratação")
        }
        
        if(PaymentCardFieldRef?.current?.fieldsWithErrorText){
            // errors = errors.concat(PaymentCardFieldRef?.current?.fieldsWithErrorText())
        }

        return errors
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate() && PaymentCardFieldRef?.current?.payment) {
                if (!loading) {
                    saveOnDataLayer({
                        event_action: `prontuario-pro+assistente-vetsmart:botao:upgrade-plano`,
                        event_label: "finalizar-upgrade"
                    })
                    const payment = {
                        vindiId: PaymentCardFieldRef?.current?.isEditField ? null : PaymentCardFieldRef?.current?.payment?.id,
                        type: "credit_card",
                        brand: PaymentCardFieldRef?.current?.cardData?.brand,
                        number: PaymentCardFieldRef?.current?.cardData?.cardNumber,
                        name: PaymentCardFieldRef?.current?.cardData?.cardName,
                        expires: PaymentCardFieldRef?.current?.cardData?.cardExpires,
                        cvv: PaymentCardFieldRef?.current?.cardData?.cardCVC,
                        installments: features?.subscription?.installments ||  1
                    }

                    setLoading(true)
                    Parse.Cloud.run("subscribe", {
                        clinicId: clinic.objectId || clinic.id,
                        products: [
                            features?.upsellPro ? "prontuario_pro" : "prontuario_ai"
                        ],
                        plan: features?.planCode,
                        customer: {
                            vindiId: PaymentCardFieldRef?.current?.payment?.customer?.id
                        },
                        payment: payment
                    })
                    .then(_subscription => {
                        props.dispatch(getProntuarioPro(_subscription))
                        addEvent("Subscription__getList", {})
                        setLoading(false)

                        saveOnDataLayer({
                            event: "purchase",
                            ecommerce: {
                                transaction_id: _subscription?.get("subscription")?.id,
                                value: getProductsAmount(_subscription?.get("plan")?.get("code"), _subscription?.get("available")),
                                tax: 0,
                                shipping: 0,
                                currency: "BRL",
                                tipo_fluxo: "upgrade",
                                items: [
                                    {
                                        item_id: PRONTUARIO_PRO, // ex: prontuario_pro
                                        item_name: PRONTUARIO_PRO_NAME, // ex: Prontuário PRO
                                        index: 0,
                                        price: fixDecimalJavascript(features?.planCode == PLAN_YEARLY ? PRONTUARIO_LEGACY_PRO_YEARLY_12X : PRONTUARIO_LEGACY_PRO_MONTHLY), // ex: 24.9
                                        quantity: features?.planCode == PLAN_YEARLY ? 12 : 1 // ex: 1
                                    },
                                    {
                                        item_id: PRONTUARIO_AI, // ex: prontuario_ai
                                        item_name: PRONTUARIO_AI_NAME, // ex: Assistente Vetsmart
                                        index: 1,
                                        price: fixDecimalJavascript(features?.planCode == PLAN_YEARLY ? PRONTUARIO_PRO_YEARLY_UPSELL_12X : PRONTUARIO_PRO_MONTHLY_UPSELL), // ex: 24.9
                                        quantity: features?.planCode == PLAN_YEARLY ? 12 : 1 // ex: 1
                                    }
                                ]
                            }
                        })

                        return resolve(clinic)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error(error)
                        Swal.fire({
                            icon: "error",
                            title: 'Desculpe',
                            text: error.code == 141 ? error.message : 'Não foi possível efetuar o pagamento, por favor, tente novamente',
                            footer: '<a href="https://pl-vetsmart.zendesk.com/hc/pt-br/categories/4410355279771-Prontu%C3%A1rio" target="_blank">Fale conosco</a>'
                        })
                        return reject()
                    })
                }
            } else {
                reject()
            }
        })
    }

    const getPriceToUpsell = () => {
        if (features?.planCode == PLAN_MONTHLY) {
            if (features?.upsellPro) {
                return PRONTUARIO_AI_MONTHLY_UPSELL
            } else {
                return PRONTUARIO_PRO_MONTHLY_UPSELL
            }
        } else {
            if (features?.upsellPro) {
                return PRONTUARIO_AI_YEARLY_UPSELL_12X
            } else {
                return PRONTUARIO_PRO_YEARLY_UPSELL_12X
            }
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [
        PaymentCardFieldRef, 
        PaymentCardFieldRef?.current,
        PaymentCardFieldRef?.current?.cardData,
        PaymentCardFieldRef?.current?.cardData?.brand,
        PaymentCardFieldRef?.current?.cardData?.cardNumber,
        PaymentCardFieldRef?.current?.cardData?.cardName,
        PaymentCardFieldRef?.current?.cardData?.cardExpires,
        PaymentCardFieldRef?.current?.cardData?.cardCVC
    ])

    useEffect(() => {
        if (features?.object) {
            if (!clinic.isOwner) {
                Swal.fire({
                    icon: "error",
                    title: 'Desculpe',
                    text: "Somente o dono da clínica pode realizar o upgrade da assinatura",
                })
                .then(_ => {
                    history.replace(`/inicio`)
                })
            } else if (!features.upsell) {
                Swal.fire({
                    icon: "error",
                    title: 'Desculpe',
                    text: "O seu plano atual não precisa de upgrade",
                })
                .then(_ => {
                    history.replace(`/configuracao-clinica/plano-e-cobranca`)
                })
            }
        }
    }, [ features ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="vspro-upgrade"
            width="80%"
            title="Upgrade de plano"
            cancel="Cancelar"
            submit={isValid ? "Upgrade de plano" : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            timeout={3000}
            onStatus={(status) => {
                var button
                switch (status) {
                    case STATUS_CANCEL:
                        button = "cancelar"
                        break
                    case STATUS_DEFAULT:
                        button = "voltar-para-upgrade"
                        break
                }
                if (button) {
                    saveOnDataLayer({
                        event_action: `${productToUpsell}:botao:${button}`,
                        event_label: "cancelar-upgrade"
                    })
                }
            }}
            onClose={() => {
                saveOnDataLayer({
                    event_action: `${productToUpsell}:botao:fechar`,
                    event_label: "finalizar-upgrade"
                })
                history.goBack()
            }}
            onCancel={() => {
                saveOnDataLayer({
                    event_action: `${productToUpsell}:botao:cancelar-alteracoes`,
                    event_label: "cancelar-upgrade"
                })
                history.goBack()
            }}
            onAfterSave={(object) => history.push("/configuracao-clinica/plano-e-cobranca")}
            VSDrawerSuccessProps={{
                title: "Alteração realizada com sucesso!"
            }} 
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar as alterações? ",
                confirm: "Cancelar Alterações",
                cancel: "Voltar para Upgrade de plano"
            }}
        >
            { loading ? (
                <div className="data-loading pt-5">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="row flex-column flex-sm-row p-3 vspro-upgrade-box g-0">
                                <div className="col">
                                    <div className="vspro-upgrade-title">
                                        Prontuário PRO + Assistente Vetsmart
                                        <IconIA className="ms-2" width="24" height="24" fill="#FF8A00" />
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconUserIA className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                        Acessos ilimitados
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconFeatures className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                        Todas as funcionalidades básicas de atendimento sem limitações
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconIAService className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                        Recursos avançados de gestão para otimizar a rotina do seu estabelecimento
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconIA className="d-block me-2" width="20" height="20" fill="#FF8A00" />
                                        Resumo do histórico clínico e diagnóstico diferencial
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconFinancial className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                        <b>Por apenas + { formattedCurrency(getPriceToUpsell(), "R$ ", 0) }/mês<sup>*</sup></b>
                                    </div>
                                </div>
                                <div className="col-auto d-flex flex-column align-items-center mt-3 mt-sm-0">
                                    <div className="vspro-upgrade-selected-plan">Plano Selecionado</div>
                                    { features?.planCode == 'yearly' ? (
                                        <div className="plan-price">Em até 12x de<br/><span className="cost">R$47,90</span> /mês<br/>ou à vista R$ 574,80/ano</div>
                                    ) : (
                                        <div className="plan-price">Por apenas<br/><span className="cost">R$59,90</span> /mês</div>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <div>* Valor sujeito a cálculo pro-rata. <button className="btn-link p-0" onClick={() => setShowProrata(true)}>Saiba mais</button></div>
                                </div>
                                <div className="col-12 mt-3">
                                    <SubscriptionAlertView irreversible />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="vspro-upgrade-subtitle">FORMA DE PAGAMENTO</div>
                            <div className="vspro-upgrade-text mt-1">A cobrança será feita no cartão cadastrado</div>
                            <PaymentCardField 
                                ref={PaymentCardFieldRef}
                                clinic={clinic}
                                features={features}
                                subscription={subscription}
                            />
                        </div>
                    </div>
                    { showProrata && (
                        <div className="prorata-container">
                            <button className="btn-link p-0" onClick={() => setShowProrata(false)}>
                                <IconArrowRight width={16} height={16} style={{ transform: "rotate(180deg)" }} />
                                Voltar
                            </button>
                            <div className="prorata-title">Como funciona o ajuste de valor (pro-rata) ao fazer um upgrade de plano?</div>
                            <div className="prorata-text">Quando você faz o upgrade de um plano para outro com valor mais alto, seja ele <b>mensal</b> ou <b>anual</b>, aplicamos o cálculo pro-rata para ajustar o valor de forma justa. Isso significa que você pagará proporcionalmente pelo tempo que usou o plano antigo e o tempo restante com o novo plano.</div>
                            <div className="prorata-subtitle">Veja um exemplo de ajuste no plano mensal:</div>
                            <div className="prorata-text">Imagine que você seja assinante do <b>Plano Prontuário PRO</b>, que custa <b>R$ 49,90/mês</b>, e decide fazer o upgrade para o novo plano <b>Plano Prontuário PRO + Assistente Vetsmart</b> que custa <b>R$ 59,90/mês</b>.</div>
                            <div className="prorata-text">Se o upgrade for feito no meio do mês, o cálculo será o seguinte:</div>
                            <div className="prorata-item"><b>Valor pelo tempo no plano antigo:</b> Você usou metade do mês com o plano de R$ 49,90. O valor proporcional por esse tempo será R$ 24,95 (metade do valor do plano).</div>
                            <div className="prorata-item"><b>Valor pelo tempo no novo plano:</b> Para os 15 dias restantes do mês, você usará o novo plano de R$ 59,90. O valor proporcional será R$ 29,95 (metade do valor do novo plano).</div>
                            <div className="prorata-item"><b>Total do mês ajustado:</b> Somando os dois valores, você pagará R$ 54,90 nesse mês, ao invés do valor cheio do novo plano (R$ 59,90). Nos meses seguintes, será cobrado o valor integral do novo plano, R$ 59,90/mês.</div>
                            <div className="prorata-subtitle">Agora veja um exemplo de ajuste no se for no plano anual:</div>
                            <div className="prorata-text">Suponha que você seja assinante do <b>Plano Prontuário PRO</b>, que custa <b>R$ 478,80/ano</b>, e decida fazer um upgrade para o plano <b>Prontuário PRO + Assistente Vetsmart</b> que custa <b>R$ 574,80/ano</b>, após 6 meses de uso. O cálculo será o seguinte:</div>
                            <div className="prorata-item"><b>Valor pelo tempo no plano antigo:</b> Como você já usou metade do ano com o plano atual, o valor proporcional será R$ 239,40 (metade do valor do plano anual).</div>
                            <div className="prorata-item"><b>Valor pelo tempo no novo plano:</b> Para os 6 meses restantes do ano, será calculado o valor proporcional do novo plano, que será R$ 287,40 (metade do valor do novo plano anual).</div>
                            <div className="prorata-item"><b>Total do ano ajustado:</b> O total que você vai pagar no ano será R$ 526,80, considerando os dois períodos (os 6 meses com o plano antigo e os 6 meses com o novo plano). Como você já pagou R$ 478,80 no início do ano, será cobrada a diferença de R$ 48,00 no momento do upgrade. No próximo ciclo, a renovação será pelo valor integral do novo plano, R$ 574,80/ano.</div>
                            <div className="prorata-subtitle">Conclusão:</div>
                            <div className="prorata-text pb-3">Esse ajuste pro-rata garante que você pague de forma justa pelo tempo que utilizou cada plano, sem ser cobrado integralmente pelo novo plano durante um período que você já pagou no plano anterior.</div>
                        </div>
                    )}
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        features: state.features
    };
};

export default connect(mapStateToProps)(ProUpgradeForm)