import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import Switch from '@mui/material/Switch';

import { saveOnTracker } from "../../../utils";
import StoreInfo from "../StoreInfo";
import { CircularProgress } from "@mui/material";

import "./DisplayStoreField.scss"
const DisplayStoreField = (props) => {

    const {
        clinic,
        value,
        onChange
    } = props

    const [ loading, setLoading ] = useState(true)

    useEffect(() => {
        if (clinic.isLoaded && clinic.object) {
            setLoading(false)
        }
    }, [ clinic ])

    return (
        <div id="display-store">
            <div className="input-title mb-2">
                <div className="row align-items-center">
                    <div className="col">
                        Ativar opção de compra
                    </div>
                    <div className="col-auto">
                        <Switch
                            checked={value}
                            onChange={({ target: { checked } }) => onChange && onChange(checked)}
                            color="primary"
                        />
                    </div>
                </div>
            </div>
            { !loading ? (
                clinic.store && !clinic.store.petlove ? (
                    <>
                        <p className="input-text">
                            Ao ativar a função <b>Opção de Compra</b>, você adicionará um link direto para a 
                            sua <a href={clinic.store.url} target="_blank">
                                Loja Online { clinic.store.name }
                            </a> nas Prescrições compartilhadas por SMS, WhatsApp e E-mail
                        </p>
                        <p className="input-text">
                            <b style={{
                                textTransform: "uppercase"
                            }}>
                                PARA GERENCIAR SUA LOJA ONLINE {clinic.store.name} <a href="https://minhaloja.petlove.com.br" target="_blank">
                                    CLIQUE AQUI
                                </a>
                            </b>
                        </p>
                    </>
                ) : (
                    <>
                        <p className="input-text">
                            Ao ativar a função <b>Opção de Compra</b>, você permitirá que o Vetsmart 
                            indique a <a href="https://petlove.com.br" target="_blank">Petlove</a> para que seus clientes adquiram os produtos prescritos através 
                            de um link nas Prescrições compartilhadas por SMS, WhatsApp e E-mail
                        </p>
                        {/* <p className="input-text">
                            <b>
                                QUER TER A SUA PRÓPRIA LOJA ONLINE 100% GRATUITA E FAZER UMA RENDA EXTRA?
                                <br/>
                                <a href="https://minhaloja.petlove.com.br/" target="_blank" onClick={() => saveOnTracker("Acessou", "Criação da Loja", "", "", "Clínica", clinic.objectId, "", "")}>
                                    CLIQUE AQUI E SAIBA MAIS
                                </a>
                            </b>
                        </p>
                        <StoreInfo /> */}
                    </>
                )
            ) : (
                <div className="row">
                    <div className="col text-center pt-3">
                        <CircularProgress size={24} />
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};

export default connect(mapStateToProps)(DisplayStoreField)