import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import storeConfig from './store/index'
import { SnackbarProvider } from 'notistack'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn-submit me-2',
    cancelButton: 'btn-outline-submit'
  },
  buttonsStyling: false,
  focusConfirm: true
})

window.Swal = withReactContent(swalWithCustomButtons)

export const store = storeConfig()

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={5}>
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Learn about: https://bit.ly/CRA-vitals
reportWebVitals()
