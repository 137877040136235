import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import { ReactComponent as IconAddO } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"
import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"

import { deleteOwner } from '../owner/Owner';
import { addEvent, diacriticSensitive } from '../../utils';
import { ACL, OrderBy_Alphabetical, OrderBy_AlphabeticalDescending, OrderBy_Oldest, OrderBy_Newest } from '../../constants';

import BoxOwner from './BoxOwner';
import VSEmpty from '../../components/vsEmpty/VSEmpty';
import VSList from '../../components/vsList/VSList';

import './OwnerList.scss'
const OwnerList = (props) => {

    const {
        clinic,
        permissions,
        history,
        location
    } = props

    const rowsPerPage = 10

    const filter = useRef(null)
    const [ firstOwnerByOnboarding, setFirstOwnerByOnboarding ] = useState(null)

    const getOwners = (searchBy, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            const queryOwner = new Parse.Query("Owner");
            queryOwner.equalTo("clinic", clinic.object);
            queryOwner.equalTo("isDeleted", false);
            if (searchBy) {
                queryOwner.matches("name", diacriticSensitive(searchBy), "im")
            }
            switch (orderBy) {
                case OrderBy_Alphabetical:
                    queryOwner.ascending("name");
                    break;
                case OrderBy_AlphabeticalDescending:
                    queryOwner.descending("name");
                    break;
                case OrderBy_Oldest:
                    queryOwner.ascending("createdAt");
                    break;
                default:
                    queryOwner.descending("createdAt");
            }
            queryOwner.addAscending("objectId")
            queryOwner.count()
            .then(_total => {
                queryOwner.skip((page - 1) * rowsPerPage)
                queryOwner.limit(rowsPerPage)
                queryOwner.include("owner");
                queryOwner.find()
                .then(_owners => {
                    resolve({
                        items: _owners,
                        total: _total
                    })
                    setFirstOwnerByOnboarding(_owners && _owners[0]?.id)
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.OWNER, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        if(firstOwnerByOnboarding){
            addEvent([
                "TourOwner__showOwnerPage",
                "TourPatient__showFirstOwnerToSelect",
                "TourPatient__selectOwnerToView"
            ], {
                ownerId: firstOwnerByOnboarding
            })
        }
    }, [ location, firstOwnerByOnboarding ])


    useEffect(() => {
        document.addEventListener("Owner__getList", onChange)
        return () => {
            document.removeEventListener("Owner__getList", onChange)
        }
    }, [])

    return (
        <div id="owner" className="row">
            <div className="col-12">
                <div className="page-content">
                    <VSList
                        ref={filter}
                        title={{
                            text: "Tutores"
                        }}
                        button={permissions?.check(ACL.OWNER, ACL.Rule.EDIT) && {
                            id: "new-owner-link",
                            text: "Cadastrar Novo Tutor(a)",
                            onClick: () => history.push(`/tutor/form/novo`)
                        }}
                        search={{
                            text: "Buscar por Nome do Tutor(a)",
                            value: props.owner || ""
                        }}
                        order={{
                            options: [
                                OrderBy_Newest,
                                OrderBy_Oldest,
                                OrderBy_Alphabetical,
                                OrderBy_AlphabeticalDescending
                            ],
                            value: OrderBy_Newest
                        }}
                        pagination={{
                            rowsPerPage: rowsPerPage
                        }}
                        onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                            return getOwners(_searchBy, _start, _end, _orderBy, _page)
                        }}
                        renderItem={(item) => (
                            <BoxOwner
                                owner={item}
                                history={history}
                                hideIcon
                                actions={(
                                    <>
                                        <div className="col-sm-auto col-12 order-3 order-sm-0 mt-3 mt-sm-0">
                                            <button
                                                className="vsbox-btn vsbox-btn-primary w-100"
                                                onClick={() => history.push(`/tutor/${item.id}`)}
                                            >
                                                Ver Cadastro Completo
                                            </button>
                                        </div>
                                        { permissions?.check(ACL.OWNER, ACL.Rule.EDIT, item) && (
                                            <div className="col-sm-auto col-6 ps-2 ps-sm-0 order-2 order-sm-0">
                                                <button
                                                    className="vsbox-btn vsbox-btn-dark w-100"
                                                    onClick={() => history.push(`/tutor/form/${ item.id }`)}
                                                >
                                                    <IconPrimaryEdit width="24" height="24" />
                                                </button>
                                            </div>
                                        )}
                                        { permissions?.check(ACL.OWNER, ACL.Rule.DELETE, item) && (
                                            <div className="col-sm-auto col-6 ps-sm-0 order-1 order-sm-0 pe-1 pe-sm-3">
                                                <button
                                                    className="vsbox-btn vsbox-btn-alert w-100"
                                                    onClick={() => deleteOwner(item, onChange)}
                                                >
                                                    <IconPrimaryTrash width="24" height="24" />
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        )}
                        renderEmpty={() => (
                            <VSEmpty
                                icon={<IconUserO width="32px" height="32px" />}
                                title="Você ainda não possui nenhuma(a) tutor(a) cadastrado(a) no Prontuário"
                                text="Cadastre o(a) primeiro(a) tutor(a) e comece a aproveitar todos os benefícios de gestão de clientes do Prontuário Vetsmart"
                            >
                                { permissions?.check(ACL.OWNER, ACL.Rule.EDIT) && (
                                    <button
                                        className="vsbox-btn vsbox-btn-primary"
                                        onClick={() => history.push(`/tutor/form/novo`)}
                                    >
                                        <IconAddO width="14" height="14" className="me-2" />
                                        Cadastrar novo tutor(a)
                                    </button>
                                )}
                            </VSEmpty>
                        )}
                        renderNotFound={() => (
                            <VSEmpty
                                icon={<IconUserO width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para a sua busca"
                                text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o botão abaixo"
                            >
                                { permissions?.check(ACL.OWNER, ACL.Rule.EDIT) && (
                                    <button
                                        className="vsbox-btn vsbox-btn-primary"
                                        onClick={() => history.push(`/tutor/form/novo`)}
                                    >
                                        <IconAddO width="14" height="14" className="me-2" />
                                        Cadastrar novo tutor(a)
                                    </button>
                                )}
                            </VSEmpty>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(OwnerList);