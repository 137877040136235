import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSMessage, { saveOnTrackerMessage } from '../../../../components/vsMessage/VSMessage';
import Blocked from '../../../blocked/Blocked';

import { canUserEditVeterinaryDocuments, useIsMount, saveOnTracker } from '../../../../utils';

const ShareVaccineForm = props => {

    const {
        clinic,
        patient,
        onCancel,
        onCloseDrawer,
        onCloseMessage
    } = props

    const MessageForm = useRef(null)

    const owner = patient?.get("owner")
    
    const canShare = canUserEditVeterinaryDocuments()
    const isMount = useIsMount();

    const link = `${process.env.REACT_APP_PRESCRIPTION}/v/${patient.id}`
    const link_pdf = `${process.env.REACT_APP_PDF}/api/v2/v/${patient?.id}`
    
    const [ errors, setErrors ] = useState([])

    const validate = () => {
        let _errors = []
        if (MessageForm.current) {
            _errors = MessageForm.current?.validate()
        }
        setErrors(_errors)
        return _errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (errors.length == 0) {

                const values = MessageForm.current?.save()
                saveOnTrackerMessage("Histórico de Vacinas", patient.id, values)

                const shareVaccine = document.getElementById("share-vaccine-form")
                shareVaccine.style.display = 'none'

                resolve()
            } else {
                reject()
            }
        })
    }

    const isChecked = (localStorageName) => {
        return localStorage.getItem(localStorageName) == "true"
    }

    const messageParams = {
        user: Parse.User.current().id,
        clinic: clinic.objectId,
        owner: owner.id,
        patient: patient.id,
        createdFromClass: "Patient",
        createdFromId: patient.id,
        createdFromObject: {
            id: patient.id
        }
    }

    return (
        <VSDrawer
            id="share-vaccine-form"
            width="50%"
            title="Compartilhar o Histórico de Vacinas"
            cancel="Cancelar Compartilhamento"
            submit={errors.length == 0 ? 
                    <span>Compartilhar Histórico<span className="d-none d-md-inline"> de Vacina</span></span> : 
                    <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            timeout={0}
            onSubmit={save}
            // onCancel={() => setShowShareVaccineForm(false)}
            // onClose={() => setShowShareVaccineForm(false)}
            onCancel={onCancel}
            onClose={onCloseDrawer}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o Compartilhamento do Histórico de Vacinas?",
                confirm: "Cancelar Compartilhamento",
                cancel: "Voltar para Compartilhar"
            }}
        >
            { canShare ? (
                <>
                    <div className="mb-4">
                        Envie uma mensagem para o tutor com as próximas vacinas e o 
                        histórico de vacinas deste animal. Essa mensagem não pode ser 
                        editada e será enviada diretamente pelo Vetsmart.
                    </div>
                    <VSMessage
                        ref={MessageForm}
                        clinic={clinic.object}
                        link={{
                            text: "Link do Histórico de Vacinas para envio manual:",
                            url: link,
                            urlToPDF: link_pdf,
                            onClick: () => {
                                saveOnTracker("Acessou", "Link Histórico de Vacinas", patient.id, "", "Histórico de Vacinas", patient.id, "", "")
                            },
                            onCopy: () => {
                                saveOnTracker("Copiou", "Link Histórico de Vacinas", patient.id, "", "Histórico de Vacinas", patient.id, "", "")
                            }
                        }}
                        onShare={() => {
                            saveOnTracker("Compartilhou", "Histórico de Vacinas", patient.id, "", "Histórico de Vacinas", patient.id, "", "WhatsApp Web")
                        }}
                        onChange={() => {
                            validate()
                        }}
                        onResend={() => {
                            const shareVaccine = document.getElementById("share-vaccine-form")
                            shareVaccine.style.display = 'block'
                        }}
                        onClose={onCloseMessage}
                        owner={owner}
                        messages={[
                            {
                                ...messageParams,
                                title: "Enviar Cópia Digital",
                                checked: isChecked("whatsappEnabled") || isChecked("smsEnabled") || isChecked("emailEnabled") || false,
                                messageTypeId: "SEND",
                                messages: [
                                    {
                                        ...messageParams,
                                        title: "Compartilhar histórico de vacina com o tutor por WhatsApp",
                                        checked: isChecked("whatsappEnabled") || false,
                                        messageTypeId: "WHATSAPP",
                                        messageTemplateId: "WHATSAPP_VACCINE_HISTORY",
                                        withSeparator: true,
                                        onChecked: (checked) => {
                                            localStorage?.setItem("whatsappEnabled", checked)
                                        },
                                    },
                                    {
                                        ...messageParams,
                                        title: "Compartilhar histórico de vacina com o tutor por SMS",
                                        checked: isChecked("smsEnabled") || false,
                                        messageTypeId: "SMS",
                                        messageTemplateId: "SMS_VACCINE_HISTORY",
                                        withSeparator: true,
                                        onChecked: (checked) => {
                                            localStorage?.setItem("smsEnabled", checked)
                                        },
                                    },
                                    {
                                        ...messageParams,
                                        title: "Compartilhar histórico de vacina com o tutor por E-mail",
                                        checked: isChecked("emailEnabled") || false,
                                        messageTypeId: "EMAIL",
                                        messageTemplateId: "EMAIL_VACCINE_HISTORY",
                                        withSeparator: false,
                                        onChecked: (checked) => {
                                            localStorage?.setItem("emailEnabled", checked)
                                        },
                                    }
                                ]
                            }
                        ]}
                    />
                </>
            ) : (
                <Blocked />
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};

export default connect(mapStateToProps)(ShareVaccineForm)