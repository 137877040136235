import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CircularProgress, Snackbar, Alert, Button } from '@mui/material';
import { 
	saveOnTracker, 
	showProOrUpgradeToProComponent,
	upgradeProEstimate,
	upgradeProSale
} from './utils';
import axios from "axios"

import { ACL, APP_VERSION } from './constants';

import Prescription from "./pages/prescription/Prescription";
import Preview from './pages/preview/Preview';
import AppMenu from "./AppMenu";
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import Tour from './components/tour/Tour';
import ExamForm from './pages/patient/tabs/exam/ExamForm';
import TermForm from './pages/patient/tabs/term/TermForm';
import ClinicForm from './pages/clinic/ClinicForm';
import SaleForm from './pages/patient/tabs/sale/SaleForm';
import SaleServiceForm from './pages/patient/tabs/sale/SaleServiceForm';
import SaleValueForm from './pages/patient/tabs/sale/SaleValueForm';
import SalePaymentForm from './pages/patient/tabs/sale/SalePaymentForm';
import SaleShare from './pages/patient/tabs/sale/SaleShare';
import TermShare from './pages/patient/tabs/term/TermShare';
import ExamShare from './pages/patient/tabs/exam/ExamShare';
import SelectClinic from './pages/blocked/SelectClinic';
import Login from './pages/blocked/Login';

import { ReactComponent as IconMaintenance } from "./assets/icon-maintenance.svg"

export const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			light: "#F3FFF4",
			main: "#00C4B4",
			dark: "#00786E",
			contrastText: "#FFFFFF"
		},
		secondary: {
			light: "#F2FAFF",
			main: "#1993F7",
			dark: "#017EB2",
			contrastText: "#FFFFFF"
		}
	},
	typography: {
		fontFamily: "Open Sans, Roboto, Arial"
	}
});

import './App.scss';
const App = (props) => {

	const {
		features,
		permissions
	} = props

	const [ loading, setLoading ] = useState(false)
	const [ refresh, setRefresh ] = useState(null)
	const [ ProntuarioUnderMaintenanceFullScreen, setProntuarioUnderMaintenanceFullScreen ] = useState(false)
	const [ ProntuarioUnderMaintenanceFullScreenMessage, setProntuarioUnderMaintenanceFullScreenMessage ] = useState("")

	const checkLatestVersion = () => {
		if (!refresh) {
			axios.get(`${process.env.REACT_APP_URL}/app.json?t=${(new Date()).getTime()}`)
			.then(({ data }) => {
				if (data?.version && data.version != APP_VERSION) {
					saveOnTracker("Visualizou", "Atualização de Versão", data.version, APP_VERSION)
					setRefresh(data)
				}
			})
			.catch(error => {
				console.error(error)
			})
		}
	}

	useEffect(() => {
		checkLatestVersion()
		const eachOneHour = 60 * 60 * 1000
		setInterval(() => {
			checkLatestVersion()
		}, eachOneHour);

		Parse.Config.get().then(config => {
			setProntuarioUnderMaintenanceFullScreen(Boolean(config.get("ProntuarioUnderMaintenanceFullScreen")))
			setProntuarioUnderMaintenanceFullScreenMessage(config.get("ProntuarioUnderMaintenanceFullScreenMessage"))
		})
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				{ ProntuarioUnderMaintenanceFullScreen ? (
					<div id="maintenance-fullscreen">
						<div className="maintenance-fullscreen-wrap">
							<IconMaintenance />
							<div className="maintenance-title">Estamos em manutenção</div>
							<div className="maintenance-text">{ProntuarioUnderMaintenanceFullScreenMessage}</div>
						</div>
					</div>
				) : loading ? (
					<div className="data-loading pt-5">
						<CircularProgress />
					</div>
				) : (
					<BrowserRouter>
						<Switch>
							<Route exact path="/clinica/:nova?" component={ClinicForm} />
							{ permissions?.check(ACL.PRESCRIPTION, ACL.Rule.VIEW) && (
								<Route exact path="/prescricao/:id_prescricao" component={Preview} />
							)}
							{ permissions?.check(ACL.PRESCRIPTION, ACL.Rule.EDIT) && (
								<Route exact path="/prescricao/:id_animal/:id_prescricao" component={Prescription} />
							)}
							{ permissions?.check(ACL.SALE, ACL.Rule.EDIT) && (
								<Route exact path="/:tipo(venda|orcamento)/:id_animal/:id_documento/:adicionar(servico|valor|agenda)?/:id_agendamento?" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...(routerProps.match.params.tipo == "venda" ? upgradeProSale : upgradeProEstimate),
										component: (
											<div>
												<SaleForm {...props} {...routerProps} />
												{ routerProps.match.params.adicionar == "servico" ? (
													<SaleServiceForm {...props} {...routerProps} />
												) : routerProps.match.params.adicionar == "valor" ? (
													<SaleValueForm {...props} {...routerProps} />
												) : undefined
												}
											</div>
										),
										access: "sales"
									})
								)} />
							)}
							{ permissions?.check(ACL.SALE, ACL.Rule.EDIT) && (
								<Route exact path="/pagamento/venda/:id_documento" component={SalePaymentForm} />
							)}
							{ permissions?.check(ACL.SALE, ACL.Rule.VIEW) && (
								<Route exact path="/compartilhar/:id_documento" component={SaleShare} />
							)}
							{ permissions?.check([ACL.EXAM_REQUEST, ACL.TERM], ACL.Rule.VIEW) && (
								<Route exact path="/documento/exame/:id_documento/:id?" render={(routerProps) => (
									<div>
										<ExamShare {...props} {...routerProps} />
										{ routerProps.match.params.id && (
											permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.EDIT) ? (
												<ExamForm {...props} {...routerProps} />
											) : undefined
										)}
									</div>
								)} />
							)}
							{ permissions?.check(ACL.TERM, ACL.Rule.VIEW) && (
								<Route exact path="/documento/atestado_termo/:id_documento/:id?" render={(routerProps) => (
									<div>
										<TermShare {...props} {...routerProps} />
										{ routerProps.match.params.id && (
											permissions?.check(ACL.TERM, ACL.Rule.EDIT) ? (
												<TermForm {...props} {...routerProps} />
											) : undefined
										)}
									</div>
								)} />
							)}
							<Route exact path="/selecionar-clinica" component={(routerProps) => (
								<>
									<Login onLoading={(isLoading) => setLoading(isLoading)} {...props} {...routerProps} />
									<SelectClinic previousURL={previousURL.current} {...props} {...routerProps} />
								</>
							)} />
							<Route render={(routerProps) => (
								<>
									<Login onLoading={(isLoading) => setLoading(isLoading)} {...props} {...routerProps} />
									<AppMenu {...routerProps} />
								</>
							)} />
						</Switch>
						<Tour />
					</BrowserRouter>
				)}
			</div>
			<Snackbar
				open={Boolean(refresh)}
				autoHideDuration={null}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					severity="warning"
					variant="filled"
					action={
						<Button
							color="inherit"
							size="small"
							onClick={() => {
								saveOnTracker("Clicou", "Atualização de Versão", refresh.version, APP_VERSION)
								setRefresh(null)
								location.reload(true)
							}}
						>
							<b>Atualizar</b>
						</Button>
					}
				>
					Uma nova versão do Prontuário está disponível
				</Alert>
			</Snackbar>
		</ThemeProvider>
	);
}

const mapStateToProps = state => {
    return {
        features: state.features,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(App);