import React, { useState, useEffect } from 'react'

import './CardFeedback.scss'

import { connect } from 'react-redux'
import { ReactComponent as ThumbUp } from "../../assets/thumb_up.svg"
import { ReactComponent as IconUpload } from "../../assets/icon-upload.svg"
import { saveOnTracker } from '../../utils'

const CardFeedback = props => {

    const {
        clinic,
        user,
        contentDetail
    } = props

    return (
        <></>
    )
    return (
        <div id="card-feedback" className="col align-items-center">
            <div className="row flex-column flex-md-row align-items-start align-items-md-center">
                <div className="col-auto mb-2 mb-md-0">
                    <ThumbUp width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                </div>
                <div className="col mb-3 mb-md-0">
                    <p className="text">Queremos saber sua opinião, conte-nos o que você está achando do <b>Prontuário Vetsmart PRO</b>.</p>
                </div>
                <div className="col col-md-auto">
                    <a
                        className="btn-outline-submit btn-square w-100"
                        href={`https://petlove-vetsmart-vetus.typeform.com/to/s0nIluRB#user_id=${user.objectId}&clinic_id=${clinic.objectId}`}
                        onClick={() => saveOnTracker("Clicou", "Feedback Footer", "Dê sua opinião", contentDetail)}
                        target="_blank"
                    >
                        Dê sua opinião
                        <IconUpload className="ms-2" fill="var(--vsprontuario-primary-color)" />
                    </a>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        user: state.user
    };
};
export default connect(mapStateToProps)(CardFeedback);